.border-none[data-v-0daec1c5]:before {
  display: none;
}
.border-none[data-v-0daec1c5]:after {
  display: none;
}
.flex-fix[data-v-0daec1c5] {
  -ms-flex: 1 1 0px;
  -webkit-box-flex: 1;
  flex: 1;
  -ms-flex-preferred-size: 0px;
      flex-basis: 0px;
  width: 1%;
}
.hide-scrollbar[data-v-0daec1c5]::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.allcover[data-v-0daec1c5] {
  position: absolute;
  top: 0;
  right: 0;
}
.center[data-v-0daec1c5] {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.ct[data-v-0daec1c5] {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.cl[data-v-0daec1c5] {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}
.clear-fix[data-v-0daec1c5] {
  *zoom: 1;
}
.clear-fix[data-v-0daec1c5]:before,
.clear-fix[data-v-0daec1c5]:after {
  content: "";
  display: table;
  clear: both;
  overflow: hidden;
}
.recommend-list[data-v-0daec1c5] {
  padding: 0 0.3rem 0.4rem;
}
.recommend-list .title .img-title[data-v-0daec1c5] {
  width: 3.22rem;
  height: 0.58rem;
  background-image: url('../../assets/img_home_cainixihuan@2x.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
@media (-webkit-min-device-pixel-ratio: 3), (min-device-pixel-ratio: 3) {
.recommend-list .title .img-title[data-v-0daec1c5] {
    background-image: url('../../assets/img_home_cainixihuan@3x.png');
}
}
