.border-none:before {
  display: none;
}
.border-none:after {
  display: none;
}
.flex-fix {
  -ms-flex: 1 1 0px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  flex: 1;
  -webkit-flex-basis: 0px;
  flex-basis: 0px;
  width: 1%;
}
.hide-scrollbar::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.allcover {
  position: absolute;
  top: 0;
  right: 0;
}
.center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.ct {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.cl {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.clear-fix {
  *zoom: 1;
}
.clear-fix:before,
.clear-fix:after {
  content: "";
  display: table;
  clear: both;
  overflow: hidden;
}
.pay-success {
  /*拼团*/
  /*引导分享*/
}
.pay-success .icon-white {
  color: #fff;
  width: 0.4rem;
  height: 0.4rem;
}
.pay-success .success-top {
  font-size: 0.44rem;
  color: #fff;
  font-weight: 500;
  line-height: 0.6rem;
  margin-bottom: 0.04rem;
}
.pay-success .success-bottom {
  opacity: 0.8;
}
.pay-success .btn-item {
  width: 1.84rem;
  height: 0.6rem;
  line-height: 0.6rem;
  text-align: center;
  border-radius: 0.44rem;
  border: 0.02rem solid #fff;
}
.pay-success header {
  height: 3rem;
  margin-bottom: 0.4rem;
  background: var(--main-color) url("../../assets/img_line@3x.png") no-repeat 0 0;
  background-size: 100% 0.96rem;
  padding: 0.5rem 1.62rem;
  font-size: 0.28rem;
  color: #fff;
}
.pay-success .success-head {
  height: 3.78rem;
  margin-bottom: 2.92rem;
  background: var(--main-color) url("../../assets/img_line@3x.png") no-repeat 0 0;
  background-size: 100% 0.96rem;
  padding: 0.86rem 0 0.48rem;
  position: relative;
  font-size: 0.28rem;
  color: #fff;
}
.pay-success .success-head .p162 {
  padding: 0 1.62rem;
}
.pay-success .success-head .txt-center {
  text-align: center;
}
.pay-success .success-head .group-box {
  width: 7.3rem;
  height: 3.4rem;
  position: absolute;
  top: 3.24rem;
  left: 0;
  right: 0;
  margin: auto;
  background: url("../../assets/bg_group@3x.png") no-repeat 0 0;
  background-size: 7.3rem 3.4rem;
  padding: 0.88rem 0.84rem 0.44rem 0.86rem;
}
.pay-success .success-head .group-box .group-list {
  width: 100%;
  height: 1.5rem;
  white-space: nowrap;
  overflow-x: auto;
}
.pay-success .success-head .group-box .group-list .group-item {
  margin-right: 0.4rem;
  display: inline-block;
  width: 0.8rem;
  height: 0.8rem;
  border-radius: 50%;
  position: relative;
}
.pay-success .success-head .group-box .group-list .group-item:last-child {
  margin-right: 0;
}
.pay-success .success-head .group-box .group-list .group-item .line {
  border: 0.02rem solid var(--main-color);
}
.pay-success .success-head .group-box .group-list .group-item img {
  border-radius: 50%;
  width: 100%;
  height: 100%;
}
.pay-success .success-head .group-box .group-list .group-item .initiator {
  position: absolute;
  width: 0.56rem;
  height: 0.28rem;
  font-size: 0.2rem;
  color: #fff;
  background: var(--main-color);
  line-height: 0.28rem;
  text-align: center;
  border-radius: 0.2rem;
  top: 0.64rem;
  left: 0;
  right: 0;
  margin: auto;
}
.pay-success .success-head .group-box .cut-box {
  font-size: 0.22rem;
  color: #999;
  line-height: 0.4rem;
}
.pay-success .success-head .group-box .cut-box .time {
  font-size: 0.28rem;
  color: var(--main-color);
  line-height: 0.32rem;
  font-weight: 500;
}
.pay-success .success-head .group-box .invite-btn {
  width: 2.4rem;
  height: 0.6rem;
  line-height: 0.6rem;
  text-align: center;
  border-radius: 0.3rem;
  background: var(--main-color);
  font-size: 0.24rem;
  color: #fff;
}
.pay-success .guide-bg {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  z-index: 1;
}
.pay-success .guide-box {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 2;
}
.pay-success .guide-box .guide-top {
  width: 4.5rem;
  height: 3.7rem;
  background: url("../../assets/img_share@3x.png") no-repeat;
  background-size: 100%;
  margin-left: 2.58rem;
  margin-bottom: 2.58rem;
}
.pay-success .guide-box .guide-btn {
  width: 2.6rem;
  height: 0.9rem;
}
